/* Live */
import cookie from "react-cookies";
export const apiUrl = process.env.REACT_APP_API_URL;
export const baseUrl = process.env.REACT_APP_BASE_URL;
export const frontURL = process.env.REACT_APP_FRONTURL;

export const defaultUniqueID = "11427810-47D6-4977-AC44-CFA53992B77A";
export const adminlimit = "10";
var accesstoken = {
  Authorization: cookie.load("accessToken"),
};

export const masterheaderconfig = {
  headers: accesstoken,
};

var clientaccesstoken = {
  Authorization: cookie.load("clientAccessToken"),
};

export const clientheaderconfig = {
  headers: clientaccesstoken,
};
